<i18n>
{
  "en": {
    "all_article_url": "/article",
    "all_article_link_label": "All posts"
  },
  "fr": {
    "all_article_url": "/articles",
    "all_article_link_label": "Tous les articles"
  },
  "ru": {
    "all_article_url": "ru/article",
    "all_article_link_label": "Все посты"
  }
}
</i18n>
<template>
  <div>
    <div class="columns is-multiline">
      <div
        v-for="content in articlesList"
        :key="content.id"
        class="column is-3"
      >
        <ImanPictureBox
          :content="content"
        />
      </div>
    </div>
    <a
      :href="$t('all_article_url')"
      class="p-2 is-underlined is-size-6"
    >
      {{ $t('all_article_link_label') }}
    </a>
  </div>
</template>

<script>

export default {
  name: "ImanArticleSection",
  props: {
    articlesCardContentId: {
      type: Array,
      default() {
        return [0, 1, 2, 3]
      }
    },
    contentList: {
      type: Array,
      default() {
        return [
          {
            "id": 81,
            "title": "Constitution de l'os de la mâchoire",
            "description": "Comment est fait l'os de la mâchoire, perte et atrophie osseuse.",
            "thumbnail": {
              "url": "thumbnail/miniature_os_machiore.png",
              "alt": "perte et atrophie osseuse"
            },
            "urlName": "constitution-os-machoire",
            "url": "/articles/constitution-os-machoire"
          }
        ]
      }
    }
  },
  data() {
    return {
      articlesList: this.contentList
    }
  }
}
</script>

<style scoped>
a {
  font-family: 'Comfortaa', cursive;
}

</style>
