<template>
  <div
    class="img-contain"
    :style="background"
  >
    <div class="body">
      <p :class="serviceClass.element">
        {{ content.title }}
      </p>
      <button
        :class="`button is-size-7-widescreen is-size-5 is-size-6-mobile ` + serviceClass.element"
        @click="navigateTo(content.routeName)"
      >
        <slot/>
      </button>
    </div>
    <div
      :class="serviceClass.overlay"
    />
  </div>
</template>

<script>
    export default {
      name: "ImanRoundedPictureBox",
      props: {
        content: {
          type: Object,
          default() {
            return {}
          }
        },
        serviceClass: {
          type: Object,
          default() {
            return {};
          }
        }
      },
      data() {
        return {
          isShow: false,
        }
      },
      computed: {
        background() {
          const img = require('../assets/img/' + this.content.img)
          return 'background-image: url("' + img + '"); background-repeat: no-repeat; background-position: center'
        }
      },
      methods: {
        navigateTo(routeName) {
          this.$router.push({name: routeName})
        }
      }
    }
</script>

<style scoped lang="scss">
  .overlay-half-circle {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 55%;
    border-radius: 0 0 32em 32em;
    background: rgba(224, 32, 64, 0.70);
    text-align: center;
    color: white;
    opacity: 0;
    transition: 0.3s opacity ease-in;
    @media only screen and (max-width: 768px) {
      opacity: 1;
    }
  }
  .show-overlay-half-circle {
    opacity: 1;
    transition: 0.3s opacity ease-out;
  }

  .element {
    opacity: 0;
    transition: 0.3s opacity ease-out;
    @media only screen and (max-width: 768px) {
      opacity: 1;
    }
  }

  .show-element {
    opacity: 1;
    transition: 0.3s opacity ease-out;
  }
  button {
    background-color: white;
    color: rgba(224, 32, 64, 0.80);
    border: 1px solid rgba(224, 32, 64, 0.80);
    margin-top: 1rem;
    &:hover {
     background-color: rgba(224, 32, 64, 0.80);
   }
  }
  .body {
    text-align: center;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 5rem;
    @media only screen and (max-width: 1540px) {
      margin-bottom: 4rem;
      font-size: 1.4rem;
    }
    z-index: 2;
    font-size: 1.5rem;
  }
  .img-contain {
    position: relative;
    min-height: 400px;
    max-width: 400px;
    margin: 0.5rem auto;
    border-radius: 50%;
    border: 1px solid #b9b9b9;
    box-shadow: 0 0 20px 7px #828282ad;
    @media only screen and (max-width: 1540px) {
      min-height: 280px;
      max-width: 280px;
    }
  }
</style>
