<template>
  <section class="columns is-centered pb-0">
    <div class="column is-10">
      <div class="card">
        <header class="card-header">
          <h2 class="card-header-title">
            {{ title }}
          </h2>
        </header>
        <div class="card-content">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImanTestimonialArticle",
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
.card {
  margin: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.70);
  .card-header-title {
    background-color: rgba(224, 32, 64, 0.70);
    font-family: 'Comfortaa', cursive;
    color: white;
  }
  .card-content {
    text-align: justify;
  }
}
</style>
