<template>
  <h4 class="is-size-4">
    <slot />
  </h4>
</template>

<script>
export default {
  name: 'ImanH4'
}
</script>

<style scoped>
  h4 {
    font-family: 'Comfortaa', cursive;
  }

</style>
