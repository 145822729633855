<template>
  <section>
    <b-carousel
      :arrow="arrow"
      :autoplay="false"
      :arrow-both="arrowBoth"
      :arrow-hover="arrowHover"
      :icon-pack="iconPack"
      :icon-prev="iconPrev"
      :icon-next="iconNext"
      :icon-size="iconSize"
      :indicator-position="indicatorPosition"
    >
      <b-carousel-item
        v-for="(carousel, i) in carousels"
        :key="i"
      >
        <section class="hero">
          <div class="hero-body">
            <figure class="image">
              <img :src="require('../assets/img/' + carousel.image)">
            </figure>
            <div class="columns">
              <div class="column is-offset-8 is-black-half-opacity">
                <div class="content">
                  <p>
                    Fournir des résultats dentaires esthétiques, fonctionnels et confortables grâce à nos laboratoires de prothèses dentaires au sein de nos cliniques dentaires.
                    <br>
                    <button class="button">
                      EN SAVOIR PLUS
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </b-carousel-item>
      <h1>{{ title }}</h1>
    </b-carousel>
  </section>
</template>

<script>
export default {
  name: 'ImanHomeHeader',
  props: {
    carousels: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default() {
        return 'Titre de niveau h1';
      }
    }

  },
  data() {
    return {
      arrow: true,
      arrowBoth: false,
      arrowHover: false,
      iconPack: 'mdi',
      iconPrev: 'chevron-left',
      iconNext: 'chevron-right',
      iconSize: 'is-large',
      indicatorPosition: 'is-top'
    }
  }
}
</script>

<style lang="scss" scoped>
  .carousel-arrow .icon {
    background: none;
    border: none;
  }
  .hero-body {
    padding: 0;
    .column{
      min-height: 700px;
      z-index: 1;
      @media only screen and (max-width: 1540px) {
        min-height: 600px;
      }
    }
  }
  button {
    background-color: #e02040;
    color: white;
    border: none;
    margin-top: 1rem;
    &:hover {
      background-color: #ce1b39;
    }
  }
  p {
    color: white;
    text-align: justify;
    padding: 2rem;
    margin-top: 15rem;
    @media only screen and (max-width: 1540px) {
      margin-top: 10rem;
    }
  }
  figure {
    position: absolute;
    top: 0;
  }
  h1 {
    font-family: 'Comfortaa', cursive;
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    color: #7f7f7f;
    font-size: 2rem;
    padding: 1rem 0;
    width: 100%;
    z-index: 1;
  }
</style>
