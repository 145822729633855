<template>
  <div class="partner columns is-centered">
    <div class="column is-10 is-hidden-mobile">
      <h3>Nos partenaires</h3>
      <b-carousel-list
        :items-to-show="4"
        :items-to-list="4"
        :repeat="true"
        :data="carouselsPartner"
        icon-size="is-medium"
        :arrow="true"
        :arrow-hover="false"
      >
        <template
          slot="item"
          slot-scope="props"
        >
          <figure class="image hoverscale">
            <img
              :src="require('../assets/img/' + props.list.image)"
              :alt="props.list.alt"
            >
          </figure>
        </template>
      </b-carousel-list>
    </div>
    <div class="column is-10 is-hidden-tablet">
      <b-carousel-list
        :items-to-show="1"
        :items-to-list="1"
        :repeat="true"
        :data="carouselsPartner"
        icon-size="is-medium"
        :arrow="true"
        :arrow-hover="false"
      >
        <template
          slot="item"
          slot-scope="props"
        >
          <figure class="image hoverscale">
            <img
              :src="require('../assets/img/' + props.list.image)"
              :alt="props.list.alt"
            >
          </figure>
        </template>
      </b-carousel-list>
    </div>
  </div>
</template>

 <script>
   export default {
     name: "ImanPartnerSection",
     props: {
       carouselsPartner: {
         type: Array,
         default() {
           return []
         }
       }
     }
   }
</script>

<style lang="scss" scoped>
  .partner {
    height: 360px;
    margin-bottom: 5em !important;
    @media only screen and (max-width: 1540px) {
      height: 260px;
    }
  }

  .image {
    padding: 3em;
  }

  h3 {
    font-size: 1.5em;
    background-color: #e02040;
    color: white;
    max-width: 30%;
    padding-left: 1em;
    @media only screen and (max-width: 1540px) {
      font-size: 1.2em;
      max-width: 45%;
    }
  }

  .carousel-list {
    background-color: rgba(255, 255, 255, 0.70);
  }

  .hoverscale {
    transition: .25s;
  }

  .hoverscale:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 768px) {
    .partner {
      height: 250px;
      margin-bottom: 4em !important;
    }
  }
</style>
