<template>
  <div class="container">
    <article>
      <div
        class="columns is-centered"
      >
        <div class="column is-10">
          <div class="content">
            <slot />
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ImanArticle'
}
</script>

<style lang="scss">
  article {
    padding: 1rem;
    text-align: justify;
  }
</style>
