<template>
  <section class="columns is-centered section">
    <div class="column is-10">
      <div class="columns is-vcentered">
        <div class="column is-4">
          <img
            :src="require('../assets/img/' + img.path)"
            :alt="img.alt"
          >
        </div>
        <div class="column has-text-white-ter has-background-primary-light-opacity py-5">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImanTestimonialHeader",
  props: {
    img: {
      type: Object,
      default() {
        return {
          path: '',
          alt: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
h1 {
  font-family: 'Comfortaa', cursive;
  color: $white;
  font-size: 1.7rem;
  font-weight: lighter;
  line-height: $title-line-height;
  padding: 0.5rem;
}
h2 {
  font-family: 'Comfortaa', cursive;
  color: $white;
  font-weight: bold;
  line-height: $title-line-height;
  font-size: 0.7rem;
  padding: 0.5rem;
}
</style>
