<template>
  <p>
    <span class="icon-text">
      <span class="icon has-text-primary">
        <i class="mdi mdi-arrow-right-thick" />
      </span>
      <a
        :href="path"
      >
        <slot />
      </a>
    </span>
  </p>
</template>

<script>
export default {
  name: "ImanMoreInfo",
  props: {
    path: {
      type: String,
      default () {
        return '/contact'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a:hover {
  color: #ff0000;
}
</style>
