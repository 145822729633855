<template>
  <div class="box hoverscale is-shady">
    <article>
      <div class="columns is-centered">
        <div class="column is-half">
          <figure class="image">
            <img
              class="is-rounded is-shady"
              :src="require('../assets/img/' + content.img)"
              :alt="content.imgAlt"
            >
          </figure>
        </div>
      </div>
      <div class="has-text-centered">
        <h3 class="c-title c-subtitle has-text-primary is-size-4 has-text-centered">
          {{ content.title }}
        </h3>
        <p>
          {{ content.body }}
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ImanCard',
  props: {
    content: {
      type: Object,
      default () {
        return {
          imgAlt: 'alt',
          title: 'Title',
          body: 'Body'
        }
      }
    }
  }
}
</script>

<style scoped>
  .hoverscale {
    transition: .25s;
  }

  .hoverscale:hover {
    transform: scale(1.05);
  }

  .box {
    padding: 0;
  }
</style>
