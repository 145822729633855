<template>
  <div class="field">
    <label
      v-if="label"
      class="label"
    >{{ label }}</label>
    <div class="control">
      <input
        :id="id"
        ref="input"
        :class="inputClass"
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :isRequired="isRequired"
        @input="validValue($event.target.value)"
        @focus="formatValue"
      >
    </div>
  </div>
</template>

<script>
  import Validator from 'validator'
  import RegexValidator from '@/data/RegexValidator'

  export default {
    name: 'ImanInput',
    props: {
      value: {
        type: String,
        default: 'Votre Nom'
      },
      label: {
        type: String,
        default: ''
      },
      validator: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'texte'
      },
      placeholder: {
        type: String,
        default: 'Votre texte'
      },
      id: {
        type: String,
        default: 'myId'
      },
      isRequired: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        inputClass: 'input is-large',
        validatorName: ''
      }
    },

    mounted () {
      this.formatValue()
    },

    methods: {
      validValue (value) {
        const validatorType = this.validator
        const regex = new RegExp(RegexValidator.regex[validatorType], 'i')
        const validationResult = Validator.matches(value, regex)
        this.inputClass = validationResult ? 'input is-large' : 'input is-large is-danger has-text-danger'
        if (!validationResult) {
          this.$emit('error', {fieldId: this.id, error: true, required: this.isRequired})
        } else {
          this.$emit('error', {fieldId: this.id, error: false, required: this.isRequired})
        }
        this.$emit('input', value)
      },

      formatValue () {
        this.validValue(this.value)
        this.$refs.input.value = this.value
      },

      selectAll (event) {
        setTimeout(function () {
          event.target.select()
        }, 0)
      }
    }
  }
</script>

<style scoped>

</style>
