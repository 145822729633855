<template>
  <div class="box hoverscale">
    <article class="columns is-vcentered">
      <div class="column is-3">
        <figure class="image my-2 mx-2">
          <img
            :src="require('../assets/img/' + content.image)"
            :alt="content.imgAlt"
            class="is-rounded"
          >
        </figure>
      </div>
      <div class="column">
        <div class="content p-3">
          <h3 class="has-text-left has-text-primary is-size-3 pt-2 mb-0">
            {{ content.title }}
          </h3>
          <p class="subtitle has-text-primary is-size-6">
            {{ content.subtitle }}
          </p>
          <div class="mr-4 has-text-justified">
            <slot />
          </div>
        </div>
        <nav class="level is-mobile">
          <div class="level-left">
            <a
              class="level-item"
              aria-label="reply"
            >
              <span class="icon is-small">
                <i
                  class="fas fa-reply"
                  aria-hidden="true"
                />
              </span>
            </a>
            <a
              class="level-item"
              aria-label="retweet"
            >
              <span class="icon is-small">
                <i
                  class="fas fa-retweet"
                  aria-hidden="true"
                />
              </span>
            </a>
            <a
              class="level-item"
              aria-label="like"
            >
              <span class="icon is-small">
                <i
                  class="fas fa-heart"
                  aria-hidden="true"
                />
              </span>
            </a>
          </div>
        </nav>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ImanCard',
  props: {
    content: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>
  .hoverscale {
    transition: .25s;
  }

  .hoverscale:hover {
    transform: scale(1.05);
  }

  .media {
    align-items: center;
  }

  .box {
    padding: 0;
  }
</style>
