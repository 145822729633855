<template>
  <div class="columns is-vcentered">
    <div
      v-if="img.position === 'left'"
      class="column is-2 is-hidden-touch"
    >
      <figure :class="imageClass">
        <img
          class="is-rounded is-shady"
          :src="require('../assets/img/' + img.path)"
          :alt="img.alt"
        >
      </figure>
    </div>
    <div :class="contentClass">
      <slot />
    </div>
    <div
      v-if="img.position === 'right'"
      class="column is-2 is-2-fullhd is-hidden-touch"
    >
      <figure :class="imageClass">
        <img
          class="is-rounded is-shady"
          :src="require('../assets/img/' + img.path)"
          :alt="img.alt"
        >
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImanHomeSection',
  props: {
    img: {
      type: Object,
      default() {
        return {
          path: '',
          position: ''
        }
      }
    }
  },
  data () {
    return {
      image: this.img
    }
  },
  computed: {
    contentClass () {
      if (this.image.position === 'left') {
        return 'column is-8 is-left'
      }
      return 'column is-8 is-right'
    },
    imageClass () {
      if (this.image.position === 'left') {
        return 'image image--left'
      }
      return 'image image--right'
    }
  }
}
</script>

<style scoped lang="scss">
  h2 {
    font-weight: lighter;
    padding-bottom: 1rem;
  }

  h4 {
    margin: 1rem 2rem 0;
  }

  .column.is-8 {
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
    p {
      text-align: justify;
    }
  }

  @media only screen and (max-width: 768px) {
    .column.is-8 {
      padding: 1rem;
    }
  }

  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 2rem;
      padding: 2rem;
    }

    .column.is-8 {
      padding: 2rem 0;
      &.is-left {
        text-align: justify;
        margin-right: 10rem;
        p {
          padding: 1rem 2rem 0 12rem;
        }
        ul {
          list-style: circle;
          padding-top: 1rem;
          padding-left: 5rem;
          padding-right: 12rem;
        }
      }
      &.is-right {
        margin-left: 10rem;
        p {
          padding: 1rem 20% 0 2rem;
        }
        ul {
          list-style: circle;
          padding-top: 1rem;
          padding-left: 5rem;
          padding-right: 20%;
        }
      }
    }

    .image {
      @media only screen and (max-width: 1540px) {
        transform: scale(1.3);
      }
      &--left {
        left: 8rem;
      }
      &--right {
        right: 8rem;
      }
    }
  }
</style>
