<template>
  <div
    class="country-card"
    :style="background"
  >
    <h3
      :class="countryCardClass.element"
      v-html="content.title"
    />
    <button
      :class="`button ` + countryCardClass.element"
      @click="navigateTo(content.routeName)"
    >
      {{ content.moreInfo }}
    </button>
    <div :class="countryCardClass.overlay" />
  </div>
</template>

<script>

export default {
  name: 'ImanCountryCard',
  props: {
    content: {
      type: Object,
      default() {
        return {}
      }
    },
    countryCardClass: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    background() {
      const img = require('../assets/img/' + this.content.img)
      return 'background-image: url("' + img + '")'
    }
  },
  methods: {
    navigateTo(routeName) {
      this.$router.push({ name: routeName })
    }
  }

}
</script>

<style lang="scss" scoped>
  .country-card {
    height: 500px;
    max-width: 300px;
    @media only screen and (max-width: 1540px) {
      height: 400px;
      padding: 15em 2em 0;
    }
    position: relative;
    text-align: center;
    padding: 20em 1em 0;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 5px 15px rgba(10, 10, 10, 0.4), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }

  .overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 130%;
    border-radius: 9em 9em 0 0;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    color: #e02040;
    opacity: 0;
    transition: 0.3s opacity ease-in;
  }

  .show-overlay {
    opacity: 1;
    transition: 0.3s opacity ease-out;
  }

  .element {
    opacity: 0;
    transition: 0.3s opacity ease-out;
    font-size: small;
  }

  .show-element {
    opacity: 1;
    transition: 0.3s opacity ease-out;
  }

  button {
    position: relative;
    background-color: #e02040;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.80);
    z-index: 2;
    &:hover {
      background-color: rgba(224, 32, 64, 0.80);
    }
    font-size: 1rem;
    @media only screen and (max-width: 1540px) {
      font-size: 0.7rem;
    }
  }

  h3 {
    position: relative;
    font-family: 'Comfortaa', cursive;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    z-index: 2;
    color: #e02040;
    @media only screen and (max-width: 1540px) {
      font-size: 1.2rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .country-card {
      background-size: cover;
      height: 550px;
      padding: 24em 2em 0;
    }
    .overlay {
      padding-bottom: 100%;
      border-radius: 11em 11em 0 0;
    }
  }

</style>
