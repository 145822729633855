<template>
  <section class="hero">
    <div class="hero-body">
      <div class="columns">
        <div class="column is-6">
          <figure>
            <img
              :src="require('@/assets/img/logo-anveli.png')"
              alt="Risques de rejet d'un implant dentaire"
            >
          </figure>
          <h1>{{ title }}</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImanHomeHeader',
  props: {
    title: {
      type: String,
      default() {
        return 'Titre de niveau h1';
      }
    }

  },
  data() {
    return {
      arrow: true,
      arrowBoth: false,
      arrowHover: false,
      iconPack: 'mdi',
      iconPrev: 'chevron-left',
      iconNext: 'chevron-right',
      iconSize: 'is-large',
      indicatorPosition: 'is-top'
    }
  }
}
</script>

<style lang="scss" scoped>
  .hero-body {
    padding: 0;
    .column{
      min-height: 800px;
      padding: 0;
    }
  }

  figure {
    background-color: rgba(54, 54, 54, 0.5);
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    margin-top: 7rem;
    text-align: right;
  }
  h1 {
    font-family: 'Comfortaa', cursive;
    background-color: rgba(54, 54, 54, 0.5);
    text-align: right;
    color: #ffffff;
    font-size: 2rem;
    padding: 1rem 2rem;
    width: 100%;
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      text-align: center;
    }
  }
</style>
