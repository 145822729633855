<i18n src="@/locales/navigation.json" />
<i18n>
{
  "en": {
    "metaTitle": "Anveli Dental - Basal implant dental care",
    "metaContent": "Anveli Dental is a specialist in basal implant and high-end dental care in 5 dental clinics abroad."
  },
  "fr": {
    "metaTitle": "Anveli Dental - Soins dentaires implant basal",
    "metaContent": "Anveli Dental, est spécialiste de l'implant basal et des soins dentaires haut de gamme dans 5 cliniques dentaires à l'étranger."
  },
  "ru": {
    "metaTitle": "Anveli Dental - Soins dentaires implant basal",
    "metaContent": "Anveli Dental, est spécialiste de l'implant basal et des soins dentaires haut de gamme dans 5 cliniques dentaires à l'étranger."
  }
}
</i18n>
<template>
  <div id="app">
    <i-navigation :items="navigationItems" />
    <router-view />
    <div
      class="scrollButton is-black-half-opacity"
      @click="scrollToTop()"
    >
      <b-icon
        icon="chevron-up"
        size="is-large"
      />
    </div>
    <footer class="mt-4">
      <div class="footer-header">
        <div class="columns is-centered has-text-centered">
          <div class="column is-half">
            <span class="icon is-large">
              <a
                href="https://web.facebook.com/anveliclinic/"
                aria-label="page facebook anveli"
              >
                <i class="mdi mdi-facebook mdi-48px" />
              </a>
            </span>
            <span class="icon is-large">
              <a
                href="https://www.instagram.com/accounts/login/?next=%2Fanveli_dental%2F&source=follow"
                aria-label="page instagram anveli"
              >
                <i class="mdi mdi-instagram mdi-48px" />
              </a>
            </span>
            <span class="icon is-large">
              <a
                href="https://www.youtube.com/channel/UC24h84rPZBeQrMEfPuRXrmg?sub_confirmation=1"
                aria-label="chaine youtube anveli"
              >
                <i class="mdi mdi-youtube mdi-48px" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import INavigation from '@/components/ImanNavigation'
import SweetScroll from 'sweet-scroll'
import {metaMixin} from "@/mixins/meta-mixin";

const scroller = new SweetScroll()

export default {
  name: 'App',
  components: {
    INavigation
  },
  computed: {
    navigationItems () {
      switch (this.$i18n.locale) {
        case 'en':
          return Object.values(this.$i18n.messages.en.items).filter(items => items)
        case 'ru':
          return Object.values(this.$i18n.messages.ru.items).filter(items => items)
        default:
          return Object.values(this.$i18n.messages.fr.items).filter(items => items)
      }
    }
  },
  methods: {
    scrollToTop() {
      scroller.to(0)
    }
  },
}
</script>

<style lang="scss">
  @import 'assets/style/scss/custom';
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
  $mdi-font-path: '~@mdi/font/fonts/';
  @import '~@mdi/font/scss/materialdesignicons';

  .scrollButton {
    position: fixed;
    bottom: 1rem;
    right: 1em;
    color: #dbdbdb;
    border-radius: 50%;
  }

  footer a {
    color: #ffffff;
  }

  .fixed-background {
    background-image: url("./assets/img/home/h1-home-d.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 115%;
    @media only screen and (max-width: 768px) {
      background-image: url("./assets/img/home/h1-home-m.jpg");
      background-size: cover;
    }
  }

  .none-background {
    background-size: 115%;
    background-image: url("./assets/img/home/h1-home-d.jpg");
    background-repeat: no-repeat;
    background-attachment: local;
    @media only screen and (max-width: 768px) {
      background-image: url("./assets/img/home/h1-home-m.jpg");
      background-attachment: fixed;
      background-size: cover;
    }

  }
</style>
