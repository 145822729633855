/* eslint-disable no-useless-escape */
export default {
  regex: {
    name: '^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ ]+$',
    country: '^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ ]+$',
    email: '^([A-Z|a-z|0-9](\\.|_){0,1})+[A-Z|a-z|0-9]\\@([A-Z|a-z|0-9])+((\\.){0,1}[A-Z|a-z|0-9]){2}\\.[a-z]{2,3}$',
    birthDate: '^(\\d{4})-(\\d{2})-(\\d{2})$',
    text: '[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ ,.\'0-9]+$'
  }
}
