export const metaMixin = {
  metaInfo () {
    let metaTitle, metaContent
    switch (this.$i18n.locale) {
      case 'en':
        metaTitle = this.$i18n.messages.en.metaTitle
        metaContent = this.$i18n.messages.en.metaContent
        break
      case 'ru':
        metaTitle = this.$i18n.messages.ru.metaTitle
        metaContent = this.$i18n.messages.ru.metaContent
        break
      default:
        metaTitle = this.$i18n.messages.fr.metaTitle
        metaContent = this.$i18n.messages.fr.metaContent
    }
    return {
      title: metaTitle,
      meta: [
        {
          property: 'og:url',
          content: window.location.href
        },
        {
          property: 'og:title',
          content: metaTitle
        },
        {
          property: 'og:description',
          content: metaContent
        },
        {
          property: 'og:image',
          content: this.openGraphImgPath
        },
        {
          vmid: 'description',
          name: 'description',
          content: metaContent
        }
      ]
    }
  }
}
