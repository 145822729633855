<i18n>
{
  "fr": {
    "contact": "Contact",
    "result": "Résultats",
    "article": "Articles",
    "contact_url": "/contact",
    "article_url": "/articles",
    "result_url": "/resultats",
    "home_url": "/",
    "result_title": "Soins dentaire avant aprés",
    "contact_title": "Formulaire de contact et demande de devis gratuit pour prestation dentaire",
    "article_title": "Nos articles et publications"
  },
  "en": {
    "contact": "Contact",
    "result": "Results",
    "article": "Article",
    "contact_url": "/contact-us",
    "article_url": "/article",
    "result_url": "/results",
    "home_url": "/en",
    "result_title": "Dental care before / after",
    "contact_title": "Contact form",
    "article_title": "Our dental posts"
  },
  "ru": {
    "contact": "Контакт",
    "result": "Результаты",
    "article": "статья",
    "contact_url": "/ru/contact-us",
    "article_url": "/ru/article",
    "result_url": "/ru/results",
    "home_url": "/ru",
    "result_title": "Dental care before / after",
    "contact_title": "Contact form",
    "article_title": "Our dental posts"
  }
}
</i18n>
<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <div class="navbar-item is-hidden-desktop">
        <a
          class="button is-danger"
          :href="$t('contact_url')"
          title="Formulaire de contact et demande de devis gratuit pour prestation dentaire"
        >
          {{ $t('contact') }}
        </a>
      </div>
      <a
        class="navbar-item is-hidden-desktop"
        href="/ru"
      >
        <img
          src="@/assets/img/russian_flag.png"
          width="40"
          height="40"
        >
      </a>
      <a
        class="navbar-item is-hidden-desktop"
        href="/en"
      >
        <img
          src="@/assets/img/english_flag.png"
          width="40"
          height="40"
        >
      </a>
      <a
        class="navbar-item is-hidden-desktop"
        href="/"
      >
        <img
          src="@/assets/img/french_flag.png"
          width="40"
          height="40"
        />
      </a>
      <a
        role="button"
        :class="{ 'navbar-burger': true, 'burger': true, 'is-active': isActive, 'has-text-white': true } "
        aria-label="menu"
        aria-expanded="false"
        data-target="anveliNavbar"
        @click="isActive = !isActive"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div
      id="anveliNavbar"
      :class="{ 'navbar-menu': true, 'is-active': isActive }"
    >
      <div class="navbar-start">
        <a
          :href="$t('home_url')"
          class="navbar-item"
          title="Accueil Anveli Dental spécialiste de l'implant dentaire"
        >
          <img
            :src="require('../assets/img/logo-anveli-small.png')"
            alt="Logo anveli dental"
            width="40"
            height="40"
          >
        </a>
        <div
          v-for="firstLevelItem in items"
          :key="firstLevelItem.id"
          class="navbar-item has-dropdown is-hoverable"
          @click="isActive = !isActive"
        >
          <router-link
            :to="firstLevelItem.url"
            active-class="is-active"
            class="navbar-link"
            :title="firstLevelItem.title"
            exact
          >
            <span class="is-size-6 is-size-5-touch">
              {{ firstLevelItem.label }}
            </span>
          </router-link>
          <div
            class="navbar-dropdown"
          >
            <div
              v-for="secondLevelItem in firstLevelItem.children"
              :key="secondLevelItem.id"
            >
              <router-link
                :to="secondLevelItem.url"
                active-class="is-active"
                :class="`navbar-item ` + secondLevelItem.show"
                :title="secondLevelItem.title"
                exact
              >
                <span class="is-size-5 is-size-6-touch">{{ secondLevelItem.label }}</span>
              </router-link>
              <div
                v-if="secondLevelItem.children"
              >
                <router-link
                  v-for="item in secondLevelItem.children"
                  :key="item.id"
                  :to="item.url"
                  active-class="is-active"
                  :class="`navbar-item is-shifted-to-left ` + item.show"
                  :title="item.title"
                  exact
                >
                  <b-icon
                    icon="circle-small"
                    type="is-primary"
                  />
                  {{ item.label }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <router-link
          :to="$t('article_url')"
          active-class="is-active"
          class="navbar-item"
          :title="$t('article_title')"
          exact
          @click.native="isActive = !isActive"
        >
          {{ $t('article') }}
        </router-link>
        <router-link
          :to="$t('result_url')"
          active-class="is-active"
          class="navbar-item"
          :title="$t('result_title')"
          exact
          @click.native="isActive = !isActive"
        >
          {{ $t('result') }}
        </router-link>
      </div>
      <div class="navbar-end is-hidden-touch">
        <a
          class="navbar-item"
          href="/ru"
        >
          <img
            src="@/assets/img/russian_flag.png"
            width="40"
            height="40"
          >
        </a>
        <a
          class="navbar-item"
          href="/en"
        >
          <img
            src="@/assets/img/english_flag.png"
            width="40"
            height="40"
          >
        </a>
        <a
          class="navbar-item"
          href="/"
        >
          <img
            src="@/assets/img/french_flag.png"
            width="40"
            height="40"
          />
        </a>
        <div class="navbar-item">
          <a
            class="button is-danger"
            :href="$t('contact_url')"
            :title="$t('contact_title')"
          >
            {{ $t('contact') }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'ImanNavigation',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isActive: false
    }
  }
}
</script>
