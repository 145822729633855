<template>
  <section class="columns is-centered section py-0">
    <div class="column is-8">
      <b-message
        :type="type"
      >
        <slot />
      </b-message>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImanTestimonialMessage",
  props: {
    type: {
      type: String,
      default: 'is-danger'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
