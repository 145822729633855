<template>
  <footer class="footer">
    <div class="columns">
      <div class="column is-2">
        <figure>
          <img
            src="@/assets/img/home/logo-footer-anveli.png"
            alt="logo anveli dental"
          >
        </figure>
      </div>
      <div class="column is-1 is-offset-7 is-hidden-mobile">
        <figure>
          <a href="https://www.youtube.com/channel/UC24h84rPZBeQrMEfPuRXrmg?sub_confirmation=1">
            <img
              src="@/assets/img/home/logo-youtube.png"
              alt="page youtube anveli dental"
            >
          </a>
        </figure>
      </div>
      <div class="column is-1 is-hidden-mobile">
        <figure>
          <a href="https://web.facebook.com/anveliclinic/">
            <img
              src="@/assets/img/home/logo-fb.png"
              alt="page facebook anveli dental"
            >
          </a>
        </figure>
      </div>
      <div class="column is-1 is-hidden-mobile">
        <figure>
          <a href="https://www.instagram.com/accounts/login/?next=%2Fanveli_dental%2F&source=follow">
            <img
              src="@/assets/img/home/logo-insta.png"
              alt="page instagram anveli dental"
            >
          </a>
        </figure>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <ul>
          <a href="/devis-gratuit">
            <li>
              <i class="mdi mdi-email mdi-24px" />
              Demander un devis
            </li>
          </a>
          <li>
            <a href="tel:+33988999933">
              <i class="mdi mdi-phone mdi-24px" />
              +33(0)9 88 99 99 33
            </a>
          </li>
          <li>
            <a href="tel:+359(0)24917588">
              <i class="mdi mdi-phone mdi-24px" />
              +359 (0) 249 175 88
            </a>
          </li>
          <li>
            <address>
              <a href="https://goo.gl/maps/YETcTYA3zNN2">
                <i class="mdi mdi-home mdi-24px" />
                ANVELI DENTAL<br>
                Ul. 21-vi Vek 27B, SOFIA<br>
                Bulgaria
              </a>
            </address>
          </li>
        </ul>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-hidden-desktop">
        <figure>
          <a href="https://www.youtube.com/channel/UC24h84rPZBeQrMEfPuRXrmg?sub_confirmation=1">
            <img
              src="@/assets/img/home/logo-youtube.png"
              alt="page youtube anveli dental"
            >
          </a>
        </figure>
      </div>
      <div class="column is-hidden-desktop">
        <figure>
          <a href="https://web.facebook.com/anveliclinic/">
            <img
              src="@/assets/img/home/logo-fb.png"
              alt="page facebook anveli dental"
            >
          </a>
        </figure>
      </div>
      <div class="column is-hidden-desktop">
        <figure>
          <a href="https://www.instagram.com/accounts/login/?next=%2Fanveli_dental%2F&source=follow">
            <img
              src="@/assets/img/home/logo-insta.png"
              alt="page instagram anveli dental"
            >
          </a>
        </figure>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'ImanFooter'
}
</script>

<style scoped>
  .footer {
    padding-bottom: 2rem;
  }

</style>
