<template>
  <h2 class="no-background is-size-5-mobile">
    <span><slot /></span>
  </h2>
</template>

<script>
export default {
  name: 'ImanH2'
}
</script>

<style lang="scss" scoped>
  h2 {
    text-align: center;
    font-family: 'Comfortaa', cursive;
  }

  h2.no-background {
    font-size: 2rem;
    font-weight: 300;
    color: #b9b9b9;
    margin: 2rem 1rem;
    position: relative;
    overflow: hidden;

    span {
      display: inline-block;
      vertical-align: baseline;
      zoom: 1;
      position: relative;
      padding: 0 20px;

      &:before, &:after {
        content: '';
        display: block;
        width: 1000px;
        position: absolute;
        top: 0.73em;
        border-top: 2px solid #b9b9b9;
      }

      &:before { right: 100%; }
      &:after { left: 100%; }
    }
  }
</style>
