<template>
  <div class="my-2 mx-2 box has-background-white-light-opacity">
    <div
      class="columns is-mobile is-multiline is-vcentered"
      @click="navigateTo(content.urlName)"
    >
      <div class="column is-12 has-text-centered">
        <h1 class="has-text-primary truncate">
          {{ content.title }}
        </h1>
      </div>
      <div class="column is-4">
        <div class="is-flex is-justify-content-center">
          <img
            :src="require('../assets/img/' + content.thumbnail.url)"
            :alt="content.thumbnail.alt"
            width="200"
            height="200"
          >
        </div>
      </div>
      <div class="column is-size-7 is-size-6-fullhd">
        <p class="has-text-left line-clamp">
          {{ content.description }}
        </p>
        <p>
          <a
            :href="content.url"
            class="is-primary"
          >{{ content.moreInfo }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "ImanPictureBox",
      props: {
        content: {
          type: Object,
          default() {
            return {}
          }
        }
      },
      data() {
        return {
          isShow: false,
          isComponentModalActive: false,
          testimonialsImg: [
            'testimonial/serge/dentiste-implants-dentaires-toutes-les-dents.jpg',
            'testimonial/serge/implants-dentaires-parodontie.jpg',
            'testimonial/serge/infection-parodontie-dechaussement-dents.jpg',
            'testimonial/serge/parodontie-dechaussement-dents.jpg'
          ]
        }
      },
      computed: {
        background() {
          const img = require('../assets/img/' + this.content.img)
          return 'background-image: url("' + img + '"); background-repeat: no-repeat; background-position: center'
        }
      },
      methods: {
        navigateTo(urlName) {
          this.$router.push({ name: urlName })
        }
      }
    }
</script>

<style scoped lang="scss">
  p {
    word-break: normal!important;
  }
  .box {
    cursor: pointer;
  }

  h1 {
    font-family: 'Comfortaa', cursive;
  }
</style>
