<template>
  <div>
    <figure class="image is-16by9">
      <iframe
        class="has-ratio"
        :src="video.src"
        frameborder="0"
        allowfullscreen
      />
    </figure>
    <b-collapse
      v-if="video.title"
      class="card"
      animation="slide"
      aria-id="contentIdForA11y3"
      :open="false"
    >
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="contentIdForA11y3"
      >
        <p class="card-header-title">
          {{ video.title }}
        </p>
        <a class="card-header-icon">
          <b-icon
            :icon="props.open ? 'menu-down' : 'menu-up'"
          />
        </a>
      </div>
      <div class="card-content">
        <div class="content">
          <slot />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'ImanVideo',
  props: {
    video: {
      type: Object,
      default() {
        return {
          title: 'Titre',
          src: ''
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  article {
    margin: 2rem 0;
  }
  .card {
    background-color: rgba(255, 255, 255, 0.70);
    .card-header-title, .card-header-icon {
      background-color: rgba(224, 32, 64, 0.70);
      font-family: 'Comfortaa', cursive;
      color: white;
    }
    .card-content {
      text-align: justify;
    }
  }
</style>
