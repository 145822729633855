<template>
  <h3 class="has-text-primary is-size-4-fullhd">
    <slot />
  </h3>
</template>

<script>
export default {
  name: 'ImanH3'
}
</script>

<style scoped>
  h3 {
    font-family: 'Comfortaa', cursive;
    width: 80%;
    font-weight: bold;
    background-color: white;
    box-shadow: 0 5px 15px rgba(10, 10, 10, 0.4), 0 0 0 0 rgba(10, 10, 10, 0.1);
    overflow-wrap: break-word;
    padding: 0.5em 2em;
    margin-top: 1em;
  }

  @media only screen and (max-width: 768px) {
    h3 {
      width: 100%;
      padding: 0.5em;
      margin-top: 0;
      margin-bottom: 1em;
    }
  }

</style>
