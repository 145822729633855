<template>
  <b-collapse
    class="card"
    animation="slide"
    aria-id="contentIdForA11y3"
    :open="false"
  >
    <div
      slot="trigger"
      slot-scope="props"
      class="card-header"
      role="button"
      aria-controls="contentIdForA11y3"
    >
      <p class="card-header-title">
        {{ title }}
      </p>
      <a class="card-header-icon">
        <b-icon
          :icon="props.open ? 'menu-down' : 'menu-up'"
        />
      </a>
    </div>
    <div class="card-content">
      <div class="content">
        <slot />
      </div>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: "ImanCollapsableCard",
  props: {
    title: {
      type: String,
      default() {
        return ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    margin: 1rem 2rem;
    background-color: rgba(255, 255, 255, 0.70);
    .card-header-title, .card-header-icon {
      background-color: rgba(224, 32, 64, 0.70);
      font-family: 'Comfortaa', cursive;
      color: white;
    }
    .card-content {
      text-align: justify;
    }
  }
</style>
