<template>
  <h1 class="is-size-5-mobile">
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'ImanH1'
}
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/scss/variables';
  h1 {
    text-align: center;
    font-family: 'Comfortaa', cursive;
    background-color: rgba(0,0,0,0.5);
    border-bottom: $primary solid 5px;
    color: $white;
    font-size: $font-size-title;
    font-weight: lighter;
    line-height: $title-line-height;
    padding: 1rem;
  }

</style>
